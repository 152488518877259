<template>
  <main>
    <Navbar></Navbar>
    <NavBox></NavBox>
    <div>
      <div style="display: flex; justify-content: space-around; padding: 30px">
        <van-button type="primary" @click="init()">查询所有信息</van-button>
        <van-button type="primary" @click="searchUser()">查询用户</van-button>
        <van-button type="error" @click="showDeleteModal=true">删除用户</van-button>
      </div>
      <div class="tableBox">
        <div style="text-align: right">总行数： {{userList.length}}</div>
        <table>
          <thead>
            <tr>
              <th>用户名</th>
              <th>sn</th>
              <th>钱包信息</th>
              <th>OpenBefore</th>
              <th>haveOpen</th>
              <th>isOpen</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in userList" :key="item._id + 'r1'">
              <td>{{ item._id }} <span v-if="item.type">({{item.type}})</span> </td>
              <td>{{item.sn}}</td>
              <td>
                <table>
                  <tr>
                    <td style="text-align:right">eth:</td>
                    <td style="text-align:left">{{ item.walletAddress }}</td>
                  </tr>
                  <tr>
                    <td style="text-align:right">deeperWallet:</td>
                    <td style="text-align:left">{{ item.deeperWallet }}</td>
                  </tr>
                  <tr>
                    <td style="text-align:right">stakingDeeperChain:</td>
                    <td style="text-align:left">{{ item.stakingDeeperChain }}</td>
                  </tr>
                </table>
              </td>
              <td>
                <div v-if="openBeforeMap[item.sn]">
                  {{!!openBeforeMap[item.sn]}}
                </div>
              </td>
              <td>
                <div v-if="haveOpenMap[item.sn]">
                  {{haveOpenMap[item.sn].haveOpen}}
                </div>
              </td>
              <td>
                <van-button type="info" size="mini" @click="changeOpen(item, true)">开通</van-button>
                <van-button type="info" size="mini" @click="changeOpen(item)">关闭</van-button>
                <van-button type="info" size="mini" @click="checkIsOpen(item)">检查</van-button>
                <div>
                  {{isOpenMap[item.sn]}}
                </div>
              </td>
              <td>
                <van-button @click="adminLogin(item._id)">登录</van-button>
                <van-button @click="changeSN(item._id)">修改SN</van-button>
                <van-button @click="searchStaking(item.walletAddress)">查询质押记录</van-button>
                <van-button @click="getStakeInfo(item._id)">查询当前质押</van-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <van-popup v-model="showDeleteModal">
      <div style="width: 600px;padding: 30px; text-align: center">
        <van-cell-group>
        <van-field label="username" v-model="deleteForm.username"></van-field>
        <van-button :loading="deleteLoading" block type="primary" @click="deleteUser">提交</van-button>
        </van-cell-group>
      </div>
    </van-popup>
  </main>
</template>

<script>
import Navbar from "@/components/Navbar/NavbarAdmin";
import NavBox from '../NavBox.vue'
import Vue from 'vue';
import jwt from 'jsonwebtoken'
function cleanObj(obj) {
  let result = {};
  Object.keys(obj).forEach(key => {
    if(obj[key]) {
      result[key] = obj[key]
    }
  })
  return result
}
export default {
  components: {
    Navbar,
    NavBox
  },
  data() {
    return {
      userList: [],
      openBeforeMap: {},
      haveOpenMap: {},
      isOpenMap: {},
      bindingListMap: {},
      showDeleteModal: false,
      deleteLoading: false,
      deleteForm: {

      },
      searchModal: false,
      searchLoading: false,
      searchForm: {
        type: '',
        _id: '',
        walletAddress: '',
        deeperChain: ''
      }
    };
  },

  mounted() {
  },
  methods: {
    getStakeInfo(id) {
      return this.$fetch({
        url: "/admin/getStakeInfo",
        method: 'POST',
        data: {
          id
        },
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
      }).then((res) => {
        console.log(res)
      }).finally(() => {
      })
    },
    init() {
      this.getUserList();
      this.getHaveOpen();
    },
    searchUser() {
      let search = prompt('请输入搜索内容')
      if (!search) {
        return
      }
      return this.$fetch({
        url: "/admin/userList",
        params: {
          search
        },
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
      }).then((res) => {
        this.userList = res.data;
      }).finally(() => {
      })
    },
    searchStaking(wallet) {
      window.sessionStorage.setItem('searchStakingWallet', wallet);
      this.$router.push('./staking')
    },
    changeSN(id) {
      let sn = prompt("请输入新的SN号")
      if (sn) {
        return this.$fetch({
          url: "/admin/changeSN",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
          },
          method: 'POST',
          data: {
            id,
            sn
          }
        }).then((res) => {
          this.getUserList()
        })
      }
    },
    deleteUser() {
      this.deleteLoading = true
      return this.$fetch({
        url: "/admin/deleteUser",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
        method: 'POST',
        data: {
          username: this.deleteForm.username
        }
      }).then((res) => {
        this.showDeleteModal = false;
        this.getUserList()
      }).finally(() => {
        this.deleteLoading = false;
      })
    },
    getBindingList(item) {
      return this.$fetch({
        url: "/admin/bingdingList",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
        params: {
          sn: item.sn
        }
      }).then((res) => {
        Vue.set(this.bindingListMap, item.sn, res.data)
        // this.isOpenMap[item._id] = res.data
        console.log(res)
      })
    },
    checkIsOpen(item) {
      this.$fetch({
        url: "/admin/isOpen",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
        params: {
          sn: item.sn
        }
      }).then((res) => {
        Vue.set(this.isOpenMap, item.sn, res.data)
        // this.isOpenMap[item._id] = res.data
        console.log(res)
      })
      this.getBindingList(item)
    },
    changeOpen(item, open) {
      return this.$fetch({
        url: "/admin/changeOpen",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
        method: 'POST',
        data: {
          sn: item.sn,
          open
        }
      }).then((res) => {
        this.checkIsOpen(item)
      })
    },
    getUserList() {
      return this.$fetch({
        url: "/admin/userList",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
      }).then((res) => {
        this.userList = res.data;
      })
    },
    getOpenBefore() {
      return this.$fetch({
        url: "/admin/openBefore",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
      }).then((res) => {
        let result = {};
        res.data.forEach(it => {
          result[it._id] = it
        })
        this.openBeforeMap = result
        console.log(res)
      })
    },
    getHaveOpen() {
      return this.$fetch({
        url: "/admin/haveOpen",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
      }).then((res) => {
        let result = {};
        res.data.forEach(it => {
          result[it._id] = it
        })
        this.haveOpenMap = result
        console.log(res)
      })
    },
    adminLogin(username) {
      return this.$fetch({
        url: "/admin/adminLogin",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
        params: {
          username
        }
      }).then((res) => {
        let token = res.data;
        const tokenData = jwt.decode(token)
        if (tokenData.dealer) {
           window.localStorage.setItem('dealerToken', token)
           window.localStorage.setItem('dealerTokenData', JSON.stringify(tokenData))
           window.open('/dealer/admin')
           return
        }
        window.localStorage.setItem('token', token)
        window.localStorage.setItem('tokenData', JSON.stringify(tokenData))
        window.open('/user')
      })
    }
  },
};
</script>

<style lang="less" scoped>
.searchBox {
  padding: 10px 30px;
  .leftBox {
    display: flex;
  }
  .rightBox {
    display: flex;
    .formItem {
      margin-left: 30px;
      display: flex;
      align-items: center;
      .titleBox {
        margin-right: 10px;
      }
    }
  }
}
.tableBox {
  padding: 30px;
  font-size: 12px;
  text-align: center;
  table {
    width: 100%;
    th,td {
      padding: 5px 2px;
    }
  }
}

.addressBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
}
</style>